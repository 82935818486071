
import Vue from "vue";
import LandingVideo from "@/components/LandingPage/LandingVideo.vue";
import LogInForm from "@/components/LandingPage/LogInForm.vue";

export default Vue.extend({
  name: "LogIn",
  components: {
    LandingVideo,
    LogInForm
  },
  props: ["mainPhrase", "mainPhraseColor"],
  computed: {
    mainPhraseStyle(): string {
      return this.mainPhraseColor ? `color:${this.mainPhraseColor}` : "";
    }
  }
});
